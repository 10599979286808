import React from 'react';
import { ImageWrapper } from './imageWrapper';
import { DocumentLink } from './documentLink';
import { LIST_TYPE_INSIGHTS } from '../../enums';

const IS_INSIGHTS_PAGE = window.listType === LIST_TYPE_INSIGHTS;

export const ListItem = ({
  ImgLgDesktop,
  ImgDesktop,
  ImgTablet,
  ImgMobile,
  ImgAlt,
  AssetType,
  PostDate,
  ItemUrl,
  Title,
  SummaryContent,
  Icon,
  IconAlt,
  ItemTime,
  ItemFirstInsightName,
  Id,
  DocumentDownload,
  DocumentDownloadName,
  DocumentDownloadUrl,
  DocumentDownloadImage,
  DocumentIsTracked,
  PublicationNumber,
  PublicationCategory,
  UrlOverride,
  FullTitle,
  InsightParentPageName
}) => {

  const createMarkup = (content) => {
    return { __html: content };
  }

  // check the window.listType, if its insights that means we're using a 3 col grid
  // 3 cols requires a wider image (ImgMobile) so render an ImageWrapper with ImgMobile values for ImgLgDesktop & ImgDesktop

  return (
    <div className="news-grid-item-wrapper" id={Id}>
      <div className="news-grid-item bg-white relative">
        <div className="news-block-image bg-navy_tint mb-0_938">
          { IS_INSIGHTS_PAGE && <ImageWrapper {
            ...{
              ImgLgDesktop,
              ImgDesktop,
              ImgTablet,
              ImgMobile,
              ImgAlt
            }
          } />}

          { !IS_INSIGHTS_PAGE && <ImageWrapper {
            ...{
              ImgLgDesktop,
              ImgDesktop,
              ImgTablet,
              ImgMobile,
              ImgAlt
            }
          } />}
        </div>
        <div className="news-block-date mb-3 px-2 text-cyan text-14px font-helvetica-neue-bold">
          {!window.showMembershipPublicationOptions && PostDate && <div className="-mb-1 uppercase">{PostDate}</div>}
          {window.showMembershipPublicationOptions && PublicationNumber && <div className="-mb-1 uppercase">{PublicationNumber}</div>}
          {window.showMembershipPublicationOptions && PublicationCategory && <div className="-mb-1 uppercase">{PublicationCategory}</div>}

          {!IS_INSIGHTS_PAGE && ItemFirstInsightName && <div className="uppercase">{ItemFirstInsightName}</div>}
          {IS_INSIGHTS_PAGE && <div className="uppercase">{InsightParentPageName}</div>}

        </div>

        <div className={`news-block-content px-2 ${window.listType === 'News' ? 'list-block-content' : ''}`}>
          <h1 className="news-block-heading text-blue_dark text-24px font-body mb-3">
            
            {window.listType !== 'Publications' && !IS_INSIGHTS_PAGE && <a href={ItemUrl} className="stretched-link hover:underline" dangerouslySetInnerHTML={createMarkup(Title)}></a>}

            {IS_INSIGHTS_PAGE && <a href={ItemUrl} className="stretched-link hover:underline" dangerouslySetInnerHTML={createMarkup(FullTitle)}></a>}
            
            {window.listType === 'Publications' &&
              <DocumentLink {...{
                DocumentDownload,
                DocumentDownloadName,
                DocumentDownloadUrl,
                DocumentIsTracked,
                ItemUrl,
                Title,
                UrlOverride
              }} />}
          </h1>

          {!IS_INSIGHTS_PAGE && <div className="news-block-text text-navy text-18px leading-tight mb-3" dangerouslySetInnerHTML={createMarkup(SummaryContent)}></div>}
        </div>

        <div className="news-block-details px-2">
          <div className="flex items-center text-18px leading-tight">

            {Icon && <img src={Icon} alt={IconAlt} style={{height: 18}} />}

            {AssetType > 0 && <span className="pl-2">{IconAlt}</span>}
          </div>

          {ItemTime && <div className="text-14px font-helvetica-neue-bold text-copper">{ItemTime}</div>}
        </div>
      </div>
    </div>
  )
}